exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-page-js": () => import("./../../../src/pages/faq-page.js" /* webpackChunkName: "component---src-pages-faq-page-js" */),
  "component---src-pages-gravity-method-colonics-js": () => import("./../../../src/pages/GravityMethodColonics.js" /* webpackChunkName: "component---src-pages-gravity-method-colonics-js" */),
  "component---src-pages-gravity-method-js": () => import("./../../../src/pages/gravity-method.js" /* webpackChunkName: "component---src-pages-gravity-method-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preparation-js": () => import("./../../../src/pages/Preparation.js" /* webpackChunkName: "component---src-pages-preparation-js" */),
  "component---src-pages-pricing-page-js": () => import("./../../../src/pages/pricing-page.js" /* webpackChunkName: "component---src-pages-pricing-page-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

